@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}

@keyframes gradientAnimation {
  0%, 100% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(-45deg, #FF69B4, #8A2BE2, #00c9ff, #4CAF50);
  background-size: 400% 400%;
  animation: gradientAnimation 15s ease infinite;
  color: white;
  padding: 20px;
  width: 100%;
  max-width: 100vw;
}


.content {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 30px 20px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 2.5em;
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 1.2;
}

.subtitle {
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 300;
}

.features-box {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin: 0 auto 30px;
  width: 100%;
  max-width: 90%;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-align: left;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateX(10px);
}

.emoji {
  font-size: 1.5em;
  margin-right: 15px;
  flex-shrink: 0;
}

.feature-item p {
  margin: 0;
}

.tagline {
  font-size: 1.2em;
  font-style: italic;
  margin-bottom: 30px;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.app-button {
  padding: 12px 24px;
  font-size: 1em;
  text-decoration: none;
  border-radius: 50px;
  transition: all 0.3s ease;
  font-weight: 600;
}

.ios-button {
  background-color: rgba(0, 122, 255, 0.8);
  color: white;
}

.android-button {
  background-color: rgba(61, 220, 132, 0.8);
  color: white;
}

.app-button:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .App {
    padding: 10px;
  }

  .content {
    padding: 20px 15px;
    width: 100%;
    border-radius: 15px;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .features-box {
    max-width: 100%;
    padding: 15px;
    margin: 0 auto 20px;
    width: 90%; /* Adjust this value as needed */
  }

  .feature-item {
    margin-bottom: 12px;
  }

  .emoji {
    font-size: 1.3em;
    margin-right: 10px;
  }

  .feature-item p {
    font-size: 0.9em;
  }

  .tagline {
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .app-button {
    width: 100%;
    padding: 10px 20px;
    margin: 0;
  }
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 30px;
}

.app-badge {
  display: inline-block;
  transition: transform 0.3s ease;
  width: 200px;  /* Set a fixed width */
  height: 60px;  /* Set a fixed height */
}

.app-badge img {
  width: 100%;
  height: 100%;
  object-fit: contain;  /* This ensures the image fits within the container without distortion */
}

.app-badge:hover {
  transform: scale(1.05);
}

@media (max-width: 600px) {
  .button-container {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .app-badge {
    width: 180px;  /* Slightly smaller on mobile */
    height: 54px;
  }
}
